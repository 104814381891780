import React from "react";
import BannerBackground from "../Assets/pngwing.png";
import BannerImage from "../Assets/hometheme.png";
import Navbar from "./Navbar";

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        {/* <div className="home-bannerImage-container">
          <img src={BannerImage} alt="" />
        </div> */}
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
          IT Solutions Built to Work Together
          </h1>
          <p className="primary-text">
          Bring harmony to your technology by partnering with a full-service managed IT service provider with complete IT solutions.
          </p>
          <p className="primary-text">
          As a top managed IT service provider, each of our service areas is aimed at driving your business goals forward while delighting your users and protecting your organization.
          </p>
        </div>
        
      </div>
    </div>
  );
};

export default Home;
