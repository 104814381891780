import React, { useState } from "react";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setLoading(true);
    axios
      .post(`https://softronicsconsults.com/api/sendEmail`, formData)
      .then((response) => {
        setFormData({
          name: "",
          email: "",
          phone: "",
          description: "",
        });
        setLoading(false);
        window.alert("Request Received Successfully..");
      })
      .catch((error) => {
        setFormData({
          name: "",
          email: "",
          phone: "",
          description: "",
        });
        setLoading(false);
        window.alert("Request Received Successfully..");
      });
  };
  return (
    <div className="contact-page-wrapper">
      <h1 className="primary-heading">Have Question In Mind?</h1>
      <h1 className="primary-heading">Let Us Help You</h1>
      <div className="contact-form-container">
        <input
          type="text"
          placeholder="yourmail@gmail.com"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div className="contact-form-container">
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Jane Doe"
          required
        />
      </div>
      <div className="contact-form-container">
        <input
          type="text"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="1234567890"
          required
        />
      </div>
      <div className="contact-form-container">
        <input
          type="text"
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Please Write Your Query Here"
          required
        />
      </div>
      <br />
      <button
        className="secondary-button"
        disabled={
          !formData.name ||
          !formData.phone ||
          !formData.description ||
          !formData.email
        }
        onClick={(e) => handleFormSubmit(e)}
      >
        {loading ? "Loading..." : "Submit"}
      </button>
    </div>
  );
};

export default Contact;
