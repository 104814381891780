import React from "react";

const Work = () => {
  const workInfoData = [
    {
      // image: PickMeals,
      title: "Fully Manages IT",
      text: "Our Fully Managed IT Support Service combines strategic and technical expertise to maximize your IT infrastructure, delight your end-users and keep your business operating a peak performance.",
    },
    {
      // image: ChooseMeals,
      title: "Managed Cybersecurity",
      text: "Protect your bussiness with deep expertise, enterprise-grade tools, and 24/7 monitoring and response.",
    },
    {
      // image: DeliveryMeals,
      title: "Disaster Recovery",
      text: "You need fast, reliable, and easy-to-use disaster recovery services that bring efficiency to protecting and recovering your data—no matter the threat or where your valuable data resides. Be Resilient, Protect Your Assets with DRaaS and BaaS",
    },{
      // image: PickMeals,
      title: "Managed User",
      text: "Stay focused on your strategic IT initiatives and increase productivity with 24/7 support services. A Support Experience Your Users Will Love",
    },
    {
      // image: ChooseMeals,
      title: "Infrastructure",
      text: "Deliver seamless user experiences by ensuring your services are always available, secure and supported. Save time for what matters with IT infrastructure management that ensures your services are continually available, physically secure, and fully supported."
    },
    {
      // image: DeliveryMeals,
      title: "Cloud Management",
      text: "Improve the efficiency, reliability, and security of you IT systems with tranformative cloud solutions. Maximize Your Growth",
    },{
      // image: DeliveryMeals,
      title: "Mobility",
      text: "Simplify complex mobile environments and manage devices throughout their lifecycle. Lift the burden of device management from IT, reduce wireless expenses, and ensure the safety and security of your devices and the data they access.",
    },
    {
      // image: DeliveryMeals,
      title: "Consulting",
      text: "Achive your strategic IT goals and give your organization a competitive advantage. Gain a competitive edge with highly effective strategies and innovative solutions with our industry-leading IT consulting services.",
    }
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <h1 className="primary-heading">IT Solutions in Harmony</h1>
        <p className="primary-text">We deliver integrated, strategic IT solutions and create harmony in your environment to tackle the complex challenges of modern IT.</p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
