import React from "react";
import AboutBackgroundImage from "../Assets/design shape.png";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <h2 className="primary-heading">Who is softronicsconsults?</h2>
        <p className="primary-text">
        softronicsconsults is a national managed service provider that believes that technology should enable our clients to be the absolute best at what they do.
        </p>
        <p className="primary-text">
        This commitment to client success is why softronicsconsults is recognized as the premier strategic managed service and security partner to strategic CIOs and IT leaders across the United States.
        </p>
        <div className="about-buttons-container">
        </div>
      </div>
    </div>
  );
};

export default About;
