import React, { useState } from "react";

const Navbar = () => {
  return (
    <nav>
      <div className="nav-logo-container">
        <h1 className="primary-subheading">softronicsconsults</h1>
      </div>
    </nav>
  );
};

export default Navbar;
