import React from "react";
import AboutBackgroundImage from "../Assets/solution page.png";

const Solutions = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <h2 className="primary-heading">OUR IT SOLUTIONS</h2>
        <p className="primary-text">
        Whether your organization is looking to fully hand off IT responsibilities or wants to integrate with existing staff, as a top managed IT service provider, we've got you covered with our best-in-class solutions 
        </p>
        <p className="primary-text">
        Technology is something that's critical to our winning, and Dataprise is as good as it gets.
        </p>
        <div className="about-buttons-container">
        </div>
      </div>
    </div>
  );
};

export default Solutions;
